import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/for/home',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/',
    name: 'Home',
    props: true,
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
  },
  {
    path: '/home',
    name: 'ShareHome',
    props: true,
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
  },
  {
    path: '/signin',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/signin/:next',
    name: 'LoginWithNext',
    props: true,
    component: () => import(/* webpackChunkName: "LoginWithNext" */ '../views/Login.vue')
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import(/* webpackChunkName: "SignUp" */ '../views/SignUp.vue')
  },
  {
    path: '/signout',
    name: 'SignOut',
    component: () => import(/* webpackChunkName: "SignOut" */ '../views/SignOut.vue')
  },
  {
    path: '/reset_password/:token',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "ResetPassword" */ '../views/ResetPassword.vue')
  },
  {
    path: '/info/update',
    name: 'UpdateInfo',
    props: true,
    component: () => import(/* webpackChunkName: "UpdateInfo" */ '../views/UpdateInfo.vue')
  },
  {
    path: '/for/work',
    name: 'SlackNotInstalled',
    props: true,
    component: () => import(/* webpackChunkName: "SlackNotInstalled" */ '../views/Slack.vue')
  },
  {
    path: '/for/work/:token',
    name: 'Slack',
    props: true,
    component: () => import(/* webpackChunkName: "Slack" */ '../views/Slack.vue')
  },
  {
    path: '/slack/user/add_intro/:token',
    name: 'SlackUserAddIntroVideoContainer',
    props: true,
    component: () => import(/* webpackChunkName: "SlackUserAddIntroVideoContainer" */ '../views/SlackUserAddIntroVideoContainer.vue')
  },
  {
    path: '/team/:token/welcome',
    name: 'PlayTeamWelcome',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "PlayTeamWelcome" */ '../views/PlayTeamWelcome.vue')
  },
  {
    path: '/done',
    name: 'Done',
    component: () => import(/* webpackChunkName: "Done" */ '../views/Done.vue')
  },
  {
    path: '/thanks',
    name: 'Thanks',
    component: () => import(/* webpackChunkName: "Thanks" */ '../views/Thanks.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "Terms" */ '../views/Terms.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "Privacy" */ '../views/Privacy.vue')
  },
  {
    path: '/create',
    name: 'CreateOrUpdateProject',
    component: () => import(/* webpackChunkName: "CreateOrUpdateProject" */ '../views/CreateOrUpdateProject.vue')
  },
  {
    path: '/update',
    name: 'UpdateProject',
    component: () => import(/* webpackChunkName: "UpdateProject" */ '../views/CreateOrUpdateProject.vue')
  },
  {
    path: '/add_ask',
    name: 'AddAsk',
    component: () => import(/* webpackChunkName: "AddAsk" */ '../views/AddAsk.vue')
  },
  {
    path: '/add_intro',
    name: 'AddIntroVideoContainer',
    component: () => import(/* webpackChunkName: "AddIntroVideoContainer" */ '../views/AddIntroVideoContainer.vue')
  },
  {
    path: '/add_video/p/:id',
    name: 'AddVideoContainerWithId',
    props: true,
    component: () => import(/* webpackChunkName: "AddVideoContainerWithId" */ '../views/AddVideoContainer.vue')
  },
  {
    path: '/add_video/:slug',
    name: 'AddParticipantVideoContainer',
    props: true,
    component: () => import(/* webpackChunkName: "AddParticipantVideoContainer" */ '../views/AddParticipantVideoContainer.vue')
  },
  {
    path: '/invited/:slug',
    name: 'InvitedParticipant',
    component: () => import(/* webpackChunkName: "InvitedParticipant" */ '../views/InvitedParticipant.vue')
  },
  {
    path: '/add_video',
    name: 'AddVideoContainer',
    component: () => import(/* webpackChunkName: "AddVideoContainer" */ '../views/AddVideoContainer.vue')
  },
  {
    path: '/play/:slug',
    name: 'PlayProject',
    props: true,
    component: () => import(/* webpackChunkName: "PlayProject" */ '../views/PlayProjectContainer.vue')
  },
  {
    path: '/invite',
    name: 'InvitePeople',
    component: () => import(/* webpackChunkName: "InvitePeople" */ '../views/InvitePeople.vue')
  },
  {
    path: '/send/invite/:project_id',
    name: 'SendWelcome',
    props: true,
    component: () => import(/* webpackChunkName: "SendWelcome" */ '../views/SendWelcome.vue')
  },
  {
    path: '/send/invite/',
    name: 'SendWelcomeNoId',
    props: true,
    component: () => import(/* webpackChunkName: "SendWelcomeNoId" */ '../views/SendWelcome.vue')
  },
  {
    path: '/invite/:project_id',
    name: 'InvitePeopleWithSlug',
    props: true,
    component: () => import(/* webpackChunkName: "InvitePeopleWithSlug" */ '../views/InvitePeople.vue')
  },
  {
    path: '/join/:slug',
    name: 'JoinProjectAsParticipant',
    component: () => import(/* webpackChunkName: "JoinProjectAsParticipant" */  '../views/JoinProjectAsParticipant.vue')
  },
  {
    path: '/link/:slug',
    name: 'PlayAndJoinProject',
    props: true,
    component: () => import(/* webpackChunkName: "PlayAndJoinProject" */ '../views/PlayAndJoinProject.vue')
  },
  {
    path: '/reminders/setup',
    name: 'AddBirthdayContacts',
    props: true,
    component: () => import(/* webpackChunkName: "AddBirthdayContacts" */ '../views/AddBirthdayContacts.vue')
  },
  {
    path: '/reminders/:slug',
    name: 'RegisterBirthdayReminders',
    props: true,
    component: () => import(/* webpackChunkName: "RegisterBirthdayReminders" */ '../views/RegisterBirthdayReminders.vue')
  },
  {
    path: '/reminders',
    name: 'RegisterBirthdayRemindersDefault',
    props: true,
    component: () => import(/* webpackChunkName: "RegisterBirthdayRemindersDefault" */ '../views/RegisterBirthdayReminders.vue')
  },
  {
    path: '/birthdate/:slug',
    name: 'UpdateContactBirthdate',
    props: true,
    component: () => import(/* webpackChunkName: "UpdateContactBirthdate" */ '../views/UpdateContactBirthdate.vue')
  },
  {
    path: '/birthdate/:slug/:project_slug',
    name: 'UpdateContactBirthdateWitSlug',
    props: true,
    component: () => import(/* webpackChunkName: "UpdateContactBirthdateWitSlug" */ '../views/UpdateContactBirthdate.vue')
  },
  {
    path: '/create/birthday/:slug',
    name: 'CreateBirthdayVideoContainer',
    props: true,
    component: () => import(/* webpackChunkName: "CreateBirthdayVideoContainer" */ '../views/CreateBirthdayVideoContainer.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/credits',
    name: 'Credits',
    component: () => import(/* webpackChunkName: "Credits" */ '../views/Credits.vue')
  },
  {
    path: '/unsubscribe',
    name: 'UnsubscribeEmail',
    component: () => import(/* webpackChunkName: "UnsubscribeEmail" */ '../views/UnsubscribeEmail.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
